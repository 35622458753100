import React from 'react';
import clsx from "clsx";
import { SimpleImg } from 'react-simple-img';
import {motion, Variants} from "framer-motion"
import PrintItemStatus from "./PrintItemStatus";
import {useToggle} from "react-use";
import {DesignConfiguration} from "../types/print-job-item";

export interface OrderDetailsItem {
  id?: number,
  label?: string,
  designId?: string,
  designConfiguration?: DesignConfiguration,
  barcode?: string,
  shopwareOrderDetailId?: number,
  printStatus: 1 | 2 | 3,
  incomingGoodsStatus: 1 | 2 | 3,
  qualityControlStatus: 1 | 2 | 3,
  job?: {
    id: number,
    name: string,
  },
  items?: OrderDetailsItem[],
}

export interface OrderDetailItemProps extends OrderDetailsItem {
}

const variants: Variants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
};

const OrderDetailItem: React.FC<OrderDetailItemProps> = ({id, label, barcode, designId, designConfiguration, printStatus, incomingGoodsStatus, qualityControlStatus, items}) => {
  const [collapsed, toggleCollapsed] = useToggle(true);
  const backgroundStyle: {backgroundColor?: string} = {};
  if (designConfiguration && !designConfiguration.texture && designConfiguration.backgroundColor) {
    backgroundStyle.backgroundColor = designConfiguration.backgroundColor;
  }
  return (
    <motion.div variants={variants} onClick={toggleCollapsed} className='bg-white hover:bg-gray-200 cursor-pointer p-4 shadow-lg grid grid-gap-4 grid-columns-12 items-center mb-4'>
      <div className='col-span-6 flex items-center'>
        <div className='w-12 relative mr-2'>
          <div className='aspect-ratio-square' style={backgroundStyle}/>
          {(designConfiguration && designConfiguration.texture) && (
            <div className='absolute inset-0 flex items-center justify-center overflow-hidden'>
              <SimpleImg src={`https://dev.oyoyo.mitting.io/designs/${designId}/thumbnails/640.jpg`} alt={''}/>
            </div>
          )}
        </div>
        <div>{label}<br/><small>{barcode}</small></div>
      </div>
      <div className='col-span-2'>
        <PrintItemStatus type={'print'} status={printStatus}/>
      </div>
      {/*<div className='col-span-2'>*/}
      {/*  <PrintItemStatus type={'incoming-goods'} status={incomingGoodsStatus}/>*/}
      {/*</div>*/}
      {/*<div className='col-span-2'>*/}
      {/*  <PrintItemStatus type={'quality-control'} status={qualityControlStatus}/>*/}
      {/*</div>*/}
      <div className={clsx('col-span-12 grid-gap-4 grid-columns-1', {
        'hidden': collapsed,
        'grid': !collapsed,
      })}>
        <div className='col-span-12'>
          <div className='font-medium'>print jobs</div>
        </div>
        {items && items.map(({job, printStatus, incomingGoodsStatus, qualityControlStatus}, i) => {
          if (!job) {
            return <div key={i}/>
          }
          return (
            <div className='col-span-12 grid grid-gap-4 grid-columns-12 items-center' key={`${id}-${i}`}>
              <div className='col-span-6'>
                {/* TODO make this a link to print job  */}
                <div>{job.name} #{job.id}</div>
              </div>
              <div className='col-span-2'>
                <PrintItemStatus type={'print'} outline={true} status={printStatus}/>
              </div>
              {/*<div className='col-span-2'>*/}
              {/*  <PrintItemStatus type={'incoming-goods'} outline={true} status={incomingGoodsStatus}/>*/}
              {/*</div>*/}
              {/*<div className='col-span-2'>*/}
              {/*  <PrintItemStatus type={'quality-control'} outline={true} status={qualityControlStatus}/>*/}
              {/*</div>*/}
            </div>
          )
        })}
      </div>
    </motion.div>
  );
};

// OrderDetailItem.defaultProps = {
// };

export default OrderDetailItem;
