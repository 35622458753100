import React, {SyntheticEvent} from 'react';
import clsx from "clsx";
import {ReactComponent as IconSelect} from "feather-icons/dist/icons/chevron-down.svg"
import {PrintJobItem} from "../../apps/production-service-platform/types/print-job-item";

export interface OySelectProps {
  className?: string,
  currentValue?: OySelectItem,
  values: OySelectItem[],

  handleChange(value: OySelectItem): void,
}

export interface OySelectItem {
  value: string | number,
  label: string,
}

const OySelect: React.FC<OySelectProps> = ({className, currentValue, values, handleChange}) => {
  // console.log(values.indexOf(currentValue));
  let value = 0;
  if (currentValue) {
    value = values.findIndex(((item: OySelectItem) => item.value === currentValue.value));
  }
  const onChange = (event: SyntheticEvent) => {
    // @ts-ignore
    handleChange(values[event.target.value]);
  };
  return (
    <div className={clsx('inline-block relative', className)}>
      <select
        value={value}
        onChange={onChange}
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded leading-tight focus:outline-none">
        {values.map((value, i) => (
          <option key={value.value} value={i}>{value.label}</option>
        ))}
      </select>
      <div
        className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 text-red-500">
        <IconSelect className="h-6 w-6"/>
      </div>
    </div>
  );
};

OySelect.defaultProps = {
  className: '',
};

export default OySelect;
