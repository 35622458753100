import {dateOptions} from "@oyoyo/ui/src/apps/production-service-platform/components/PrintJobsOverviewFilter";
import {PrintJob, PrintJobFilter} from "@oyoyo/ui/src/apps/production-service-platform/types/print-job";
import {OySelectItem} from "@oyoyo/ui/src/components/OySelect/OySelect";
import React, {useState} from 'react';
import gql from 'graphql-tag';
import {useQuery, useSubscription} from 'urql';
import { Link, useHistory } from 'react-router-dom';
import { format, parseISO, startOfWeek, lastDayOfWeek, subWeeks, startOfMonth, lastDayOfMonth, subMonths, startOfYear, lastDayOfYear, subYears } from 'date-fns';
// import {PrintJobsOverviewPage as Page, PrintJob, PrintJobFilter, OySelectItem} from '@oyoyo/oyoyo-ui';
import { default as Page } from '@oyoyo/ui/src/apps/production-service-platform/pages/print-jobs-overview';
import Error from '../components/Error';
import {executeQuery} from "../api/hasura";
// import axios, {AxiosResponse} from "axios";
// import {convertPrintjobItem} from "../api/convert";
import {useSessionStorage} from "react-use";

// SELECT "jobId", json_build_object('itemsTotal', count(*), 'itemsPrinted', count(*) filter (where print_status = 2), 'itemsRescheduled', count(*) filter (where print_status = 3)) FROM printjobitems WHERE "jobId" IN (83, 49 ,240) GROUP BY "jobId"

const printJobsOverviewQuery = gql`
  query printJobsOverview($where: printjobsoverview_bool_exp!, $orderBy: [printjobsoverview_order_by!], $limit: Int!, $offset: Int!) {
    printjobsoverview(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
      id
      name
      material
      created_at
      status
      version
      itemAggregations
    }
    printjobsOverviewInfo: printjobsoverview_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const getQueryWhere = (filter: PrintJobFilter) => {
  const where: any = {};
  if (filter) {
    if (filter.material && filter.material.value) {
      where.material = {'_eq': filter.material.value}
    }
    if (filter.status && filter.status.value) {
      where.status = {'_eq': filter.status.value}
    }
    if (filter.date && filter.date.value) {
      let day = new Date();
      let minDate = '', maxDate = '';
      switch (filter.date.value) {
        case 1: // this week
          minDate = format(startOfWeek(day, {weekStartsOn: 1}), 'yyyy-MM-dd');
          maxDate = format(lastDayOfWeek(day, {weekStartsOn: 1}), 'yyyy-MM-dd');
          break;
        case 2: // last week
          day = subWeeks(day, 1);
          minDate = format(startOfWeek(day, {weekStartsOn: 1}), 'yyyy-MM-dd');
          maxDate = format(lastDayOfWeek(day, {weekStartsOn: 1}), 'yyyy-MM-dd');
          break;
        case 3: // this month
          minDate = format(startOfMonth(day), 'yyyy-MM-dd');
          maxDate = format(lastDayOfMonth(day), 'yyyy-MM-dd');
          break;
        case 4: // last month
          day = subMonths(day, 1);
          minDate = format(startOfMonth(day), 'yyyy-MM-dd');
          maxDate = format(lastDayOfMonth(day), 'yyyy-MM-dd');
          break;
        case 5: // this year
          minDate = format(startOfYear(day), 'yyyy-MM-dd');
          maxDate = format(lastDayOfYear(day), 'yyyy-MM-dd');
          break;
        case 6: // last year
          day = subYears(day, 1);
          minDate = format(startOfYear(day), 'yyyy-MM-dd');
          maxDate = format(lastDayOfYear(day), 'yyyy-MM-dd');
          break;
      }
      where.created_at = {_gte: minDate, _lte: maxDate}
    }
    if (filter.search) {
      where.name = {'_like': `%${filter.search}%`}
    }
  }
  return where;
};

const getQueryOrderBy = (filter: PrintJobFilter) => {
  const orderBy: any = {};
  if (filter && filter.sort) {
    switch (filter.sort.value) {
      case 1:
        orderBy.created_at = 'asc';
        break;
      case 2:
        orderBy.status = 'asc';
        orderBy.created_at = 'desc';
        break;
      case 3:
        orderBy.material = 'asc';
        orderBy.created_at = 'desc';
        break;
      case 0:
      default:
        orderBy.created_at = 'desc';
        break;
    }
  }
  return orderBy;
};

const printJobItemQuery = gql`
  query printJobItemQueryQC($barcode: String!) {
    printjobitems(where: {barcode: {_eq: $barcode}, print_status: {_eq: 1}}, order_by: {id: desc}, limit: 1) {
      jobId
    }
  }
`;

interface PrintJobsOverviewResponse {
  printjobsoverview: PrintJobsOverviewEntry[];
}

interface PrintJobsOverviewEntry {
  id: number;
  name: string;
  material: number;
  created_at: string;
  status: number;
  version: number;
  itemAggregations: {
    itemsPrinted: number;
    itemsTotal: number;
    itemsRescheduled: number;
  };
}

const convert = (jobs: PrintJobsOverviewEntry[]): PrintJob[] => {
  return jobs.map(job => {
    return {
      id: job.id,
      name: job.name,
      material: job.material,
      createdAt: format(parseISO(job.created_at), 'yyyy-MM-dd'),
      status: job.status,
      version: job.version,
      itemsPrinted: job?.itemAggregations?.itemsPrinted || 0,
      itemsTotal: job?.itemAggregations?.itemsTotal || 0,
      items: [],
      itemsRescheduled: job?.itemAggregations?.itemsRescheduled || 0,
      deliveryDate: undefined,
      trackingCodes: []
    };
  });
};

const PrintJobsOverviewPage: React.FC<{scan: boolean}> = ({scan}) => {
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterState, setFilterState] = useState<PrintJobFilter>({
    date: dateOptions[1],
  });
  const [, setBarcode] = useSessionStorage('barcode', '', true);

  const [res] = useQuery(
    { query: printJobsOverviewQuery, requestPolicy: 'network-only', variables: {where: getQueryWhere(filterState), orderBy: getQueryOrderBy(filterState), limit: pageSize, offset: (currentPage - 1) * pageSize || 0} }
  );

  console.log(res);

  const history = useHistory();

  if (res.error !== undefined) {
    return <Error>{res.error.message}</Error>;
  }

  const lastPage = Math.ceil((res?.data?.printjobsOverviewInfo.aggregate.count || 1) / pageSize)
  // const previousPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  // const nextPage = () => setCurrentPage(currentPage < lastPage ? currentPage + 1 : lastPage);
  const printJobs = res.data === undefined ? [] : convert(res.data.printjobsoverview);

  const openDetails = (id: number, barcode?: string) => {
    if (barcode) {
      setBarcode(barcode);
    }
    history.push(`/print-jobs/${id}`);
  };

  const downloadArchive = (id: number) => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/archive`);
  };

  const switchPage = (page: OySelectItem) => {
    setCurrentPage(page.value as number);
  };

  const setFilter = (filter: PrintJobFilter) => {
    setFilterState(filter);
  };

  const validateBarcode = async (barcode: string): Promise<boolean> => {
    try {
      const {printjobitems: [item]} = await executeQuery(printJobItemQuery, {barcode}, 'network-only');
      if (item) {
        openDetails(item.jobId, barcode);
        return Promise.resolve(true);
      }
      return Promise.reject(false);
    } catch (e) {
      console.error(e);
      return Promise.reject(false);
    }
  };

  const breadcrumbs = [
    {label: 'home', url: '/'},
    {label: 'print jobs overview', url: '/print-jobs'},
  ];

  return (
    <Page breadcrumbs={breadcrumbs} breadcrumbsComponent={Link} printJobs={printJobs} fetching={res.fetching} scan={scan} currentPage={currentPage} pagesTotal={lastPage} openDetails={openDetails} downloadArchive={downloadArchive} switchPage={switchPage} setFilter={setFilter} validateBarcode={validateBarcode} />
  );
};

export default PrintJobsOverviewPage;
