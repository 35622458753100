import React from 'react';
import TwLogo from "../../../components/TwLogo/TwLogo";

const Header: React.FC = () => {
  return (
    <header className='flex items-center justify-center h-24'>
      <TwLogo/>
    </header>
  );
};

export default Header;
