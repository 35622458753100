import React, {useState} from 'react';
import clsx from "clsx";
import OyInput from "../../../components/OyInput/OyInput";

export interface ScanInputProps {
  className?: string,
  validateInput(value: string | number): Promise<boolean>,
}

const ScanInput: React.FC<ScanInputProps> = ({className, validateInput}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const handleChange = (value: string) => {
    const v = value.replace(/[^0-9A-Z]/g,'');
    setValue(v);
    if (v.length >= 10) {
      validateInput(v)
        .then(() => {
          setValue('');
          setError(false);
        })
        .catch(() => {
          console.error('invalid code'); // TODO add red border?
          setError(true);
        })
      ;
      // setError(true)
      // navigation.navigate(`/quality-assurance/${encodeURIComponent(event.target.value)}`)
    }
  };
  return (
    <div className={clsx('flex items-center justify-center mb-4 border border-gray-200 px-8 py-4 bg-white shadow-xl pointer-events-auto', className)}>
      <div className='font-medium mr-4'>scan the barcode or type it manually</div>
      <OyInput className={clsx({
        'border-red-500': error,
      })} currentValue={value} handleChange={handleChange} autoFocus={true}/>
    </div>
  );
};

ScanInput.defaultProps = {
  className: '',
};

export default ScanInput;
