import React from 'react';
import clsx from "clsx";

export interface OyButtonProps {
  className?: string,
  type?: 'submit' | 'reset' | 'button',
  label?: string,
  color?: 'blue' | 'green' | 'purple' | 'gray' | 'red' | 'transparent',
  size?: 'sm' | 'default' | 'xl',
  icon?: any,
  iconPosition?: 'left' | 'right',
  disabled?: boolean,

  onClick?(event: React.MouseEvent<HTMLElement>): void,
}

const OyButton: React.FC<OyButtonProps> = ({className, label, size, color, icon, iconPosition, children, disabled, ...props}) => {
  const buttonClassNames = clsx(
    'inline-block text-white font-medium rounded',
    {'flex items-center justify-center': icon},
    {'py-1 px-2': size === 'sm'},
    {'py-2 px-4': size === 'default'},
    {'py-4 px-8 text-xl': size === 'xl'},
    {'bg-blue-500 hover:bg-blue-800': color === 'blue'},
    {'bg-green-500 hover:bg-green-800': color === 'green'},
    {'bg-purple-500 hover:bg-purple-800': color === 'purple'},
    {'bg-red-500 hover:bg-red-800': color === 'red'},
    {'bg-gray-500 hover:bg-gray-800': color === 'gray'},
    {'border-solid border border-white hover:border-gray-200': color === 'transparent'},
    {'disabled:text-gray-200 disabled:opacity-50 disabled:cursor-not-allowed': disabled},
    className
  );
  return (
    <button className={buttonClassNames} disabled={disabled} {...props}>
      {(icon && iconPosition === 'left') && icon}
      {label}
      {(icon && iconPosition === 'right') && icon}
      {children}
    </button>
  );
};

OyButton.defaultProps = {
  className: '',
  label: '',
  color: 'blue',
  size: 'default',
  iconPosition: 'left',
  disabled: false,
};

export default OyButton;
