import React from 'react';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { default as Page } from '@oyoyo/ui/src/apps/production-service-platform/pages/fulfillment';
import {executeQuery} from "../api/hasura";

export const searchOrderNumberQuery = gql`
  query searchOrderNumberQuery($orderNumber: Int!) {
    printjobitems(where: {shopware_order_number: {_eq: $orderNumber}}, limit: 1) {
      shopware_order_number
    }
  }
`;

const FulfillmentPage: React.FC = () => {
  const history = useHistory();
  const navigate = (path: string) => () => history.push(path);
  const searchOrderNumber = async (orderNumber: string) => {
    const {printjobitems: [item]} = await executeQuery(searchOrderNumberQuery, {orderNumber}, 'network-only');
    if (item) {
      // console.log(item);
      history.push(`/order-details/${item.shopware_order_number}`);
    }
  };
  return (
    <Page printJobsAction={navigate('/print-jobs')}
          incomingGoodsAction={navigate('/incoming-goods-inspection')}
          qualityControlAction={navigate('/quality-control')}
          searchOrderNumber={searchOrderNumber}
    />
  );
};

export default FulfillmentPage;
