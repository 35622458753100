import {PrintJobItem} from "@oyoyo/ui/src/apps/production-service-platform/types/print-job-item";

// @ts-ignore
export const convertPrintjobItem = (item: any, statusField: string, files: {name: string, thumbnail: string}[] | undefined = []): PrintJobItem => {
    // console.log(item, statusField);
    const isNaturalsProduct = item.label.indexOf('OY-NAT-') !== -1;
    const isNewDesign = isNaturalsProduct ? false : /^[a-f0-9]{32}\/design/.test(item.design_configuration.texture) || item.productVariantId;
    let designUrl = item?.design_configuration?.texture ? isNewDesign ? `https://storage.designer.textilwerk.io/designs/${item.design_configuration.texture.substr(0, 32)}/thumbnails/640.jpg`: `https://dev.oyoyo.mitting.io/designs/${item.design_id}/thumbnails/640.jpg` : 'https://assets.textilwerk.com/media/image/1f/6c/2a/materialvorschau_05_leinen_140x140.jpg';
    if (isNaturalsProduct && files) {
      const name = item.label.substring(0, item.label.lastIndexOf('_'));
      const thumb = files.find((file) => file.name === name)?.thumbnail;
      // console.log(name, files, thumb)
      if (thumb) {
        designUrl = thumb;
      }
    }
    return {
        id: item.id,
        label: item.label,
        designId: item.design_id,
        designUrl,
        designConfiguration: {
            texture: isNaturalsProduct ? true : item?.design_configuration?.texture,
            backgroundColor: item?.design_configuration?.backgroundColor,
        },
        barcode: item.barcode,
        job: item.job,
        status: item[statusField],
    };
};
