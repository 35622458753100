import React from 'react';
import {ReactComponent as IconDetails} from "feather-icons/dist/icons/chevron-right.svg"
import clsx from "clsx";
import OyButton from "../../../components/OyButton/OyButton";
import {PrintJob, PrintjobStatus, printjobStatusLabel} from "../types/print-job";
import {motion, Variants} from "framer-motion"
import {ReactComponent as IconDownload} from "feather-icons/dist/icons/download-cloud.svg"

// TODO https://www.freecodecamp.org/news/https-medium-com-nakayama-shingo-creating-responsive-tables-with-pure-css-using-the-grid-layout-module-8e0ea8f03e83/

export interface PrintJobsOverviewTableProps {
  items: PrintJob[],
  handleOnClick(id: number): void,
  downloadArchive(id: number): void,
}

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item: Variants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
};

const PrintJobsOverviewTable: React.FC<PrintJobsOverviewTableProps> = ({items, handleOnClick, downloadArchive}) => {
  const onClick = (id: number) => () => handleOnClick(id);
  const onDownloadArchive = (id: number) => (event: React.MouseEvent<HTMLElement>) => {
    // event.preventDefault();
    event.stopPropagation();
    downloadArchive(id);
  };
  return (
    <motion.table className='w-full' variants={container} initial={'hidden'} animate={'show'}>
      <thead>
      <tr className='font-medium'>
        <td className='pl-2 pr-1 py-2'>print order name</td>
        <td className='px-1 py-2'>date</td>
        <td className='px-1 py-2'>print status</td>
        <td className='px-1 py-2'>print items</td>
        <td className='px-1 py-2'>download PDFs</td>
        <td className='py-2'/>
      </tr>
      </thead>
      <tbody>
      {items.map(({id, name, createdAt, status, version, itemsPrinted, itemsTotal, itemsRescheduled, deliveryDate, trackingCodes}) => {
        const printed = itemsRescheduled ? (
          <>
            {itemsPrinted}/{(itemsTotal - itemsRescheduled)} <span className='text-red-500'>({itemsTotal})</span>
          </>
        ) : `${itemsPrinted}/${itemsTotal}`;
        const rowClass = clsx('border-t-8 border-gray-100 cursor-pointer', {
          'bg-white hover:bg-gray-200': status !== PrintjobStatus.DONE,
          'bg-green-100 hover:bg-green-200': status === PrintjobStatus.DONE && !itemsRescheduled,
          'bg-red-100 hover:bg-red-200': status === PrintjobStatus.DONE && itemsRescheduled,
        });
        const statusClass = clsx('px-1 py-2', {
          'text-blue-500': status === PrintjobStatus.NEW,
          'text-yellow-500': status === PrintjobStatus.IN_PROGRESS,
          'text-green-500': status === PrintjobStatus.DONE && !itemsRescheduled,
          'text-red-500': status === PrintjobStatus.DONE && itemsRescheduled,
        });
        return (
          <motion.tr key={id} className={rowClass} onClick={onClick(id)} variants={item}>
            <td className='pl-2 pr-1 py-2'>{name}</td>
            <td className='px-1 py-2'>{createdAt}</td>
            <td className={statusClass}>{printjobStatusLabel(status)}</td>
            <td className='px-1 py-2'>{printed}</td>
            <td className='px-1 py-2'>
              <OyButton size={'sm'} className={'text-sm'} color={'blue'} label={'download'} icon={<IconDownload className={'w-4 h-4 ml-1'}/>}
                        iconPosition={'right'} onClick={onDownloadArchive(id)}/>
            </td>
            <td className='px-1 w-6 text-red-500'>
              <IconDetails className='w-6 h-6'/>
            </td>
          </motion.tr>
        );
      })}
      </tbody>
    </motion.table>
  );
};
//
// const PrintJobsOverviewTable: React.FC<PrintJobsOverviewTableProps> = ({items}) => {
//     return (
//         <ol>
//             <li className='grid grid-columns-5'>
//                 <div>print order name</div>
//                 <div>date</div>
//                 <div>print status</div>
//                 <div>print items</div>
//                 <div>delivery / tracking</div>
//             </li>
//             {items.map(({name, date, status, itemsPrinted, itemsTotal, deliveryDate}) => (
//                 <li key={name} className='grid grid-columns-5 grid-gap-4'>
//                     <div>{name}</div>
//                     <div>{date.toLocaleString()}</div>
//                     <div>{status}</div>
//                     <div>{itemsPrinted}/{itemsTotal}</div>
//                     <div>{deliveryDate}</div>
//                 </li>
//             ))}
//         </ol>
//     );
// };

export default PrintJobsOverviewTable;
