import React, {useState} from 'react';
import OySelect, {OySelectItem} from "../../../components/OySelect/OySelect";
import OySearchInput from "../../../components/OySearchInput/OySearchInput";
import {PrintJobFilter} from "../types/print-job";
import OyButton from "../../../components/OyButton/OyButton";
import {useLocalStorage} from "react-use";

export interface PrintJobsOverviewFilterProps {
  isScanning: boolean
  setIsScanning(isScanning: boolean): void
  handleChange(filter: PrintJobFilter): void
}

export const materialOptions = [
  {value: 0, label: 'material'},
  {value: 19, label: 'cotton'},
  {value: 20, label: 'basic'},
  {value: 21, label: 'soft'},
  {value: 22, label: 'linen'},
  {value: 69, label: 'outdoor'},
  {value: 87, label: 'cotton-twill'},
  {value: 10301, label: 'naturals-linen-off-white'},
  {value: 10302, label: 'naturals-linen-nature'},
  {value: 10303, label: 'naturals-linen-warm-grey'},
  {value: 10304, label: 'naturals-linen-graphite'},
  {value: 10311, label: 'naturals-linen-sage-green'},
  {value: 10312, label: 'naturals-linen-blue-grey'},
  {value: 10313, label: 'naturals-linen-cinnamon'},
  {value: 10314, label: 'naturals-linen-antique-pink'},
];

export const statusOptions = [
  {value: 0, label: 'status'},
  {value: 1, label: 'new'},
  {value: 2, label: 'in progress'},
  {value: 3, label: 'done'},
];

export const dateOptions = [
  {value: 0, label: 'date'},
  {value: 1, label: 'this week'},
  {value: 2, label: 'last week'},
  {value: 3, label: 'this month'},
  {value: 4, label: 'last month'},
  {value: 5, label: 'this year'},
  {value: 6, label: 'last year'},
];

export const sortOptions = [
  {value: 0, label: 'by newest'},
  {value: 1, label: 'by oldest'},
  {value: 2, label: 'by print status'},
  {value: 3, label: 'by material'},
];

const PrintJobsOverviewFilter: React.FC<PrintJobsOverviewFilterProps> = ({handleChange, isScanning, setIsScanning}) => {
  const [filter, setFilter] = useLocalStorage<PrintJobFilter>('pjo-filter', {
    search: '',
    material: materialOptions[0],
    status: statusOptions[0],
    date: dateOptions[1],
    sort: sortOptions[0],
  });
  const setValue = (name: string) => (value: any) => {
    setFilter({...filter, [name]: value});
  };
  const getValue = (name: 'search' | 'material' | 'status' | 'date' | 'sort'): any => {
    return filter[name];
  };
  handleChange(filter);
  return (
    <div className='flex justify-between flex-col lg:flex-row'>
      <OySearchInput id={'filter_search'} currentValue={getValue('search')} handleChange={setValue('search')} changeOnEnter={true} />
      <OyButton className='my-2 lg:my-0' color={'purple'} label={`${isScanning ? 'cancel' : 'scan'}`} onClick={() => setIsScanning(!isScanning)}/>
      <div className='flex items-center'>
        <label className='font-medium'>filter:</label>
        <OySelect className='mx-2' currentValue={getValue('material')} values={materialOptions} handleChange={setValue('material')}/>
        <OySelect className='mx-2' currentValue={getValue('status')} values={statusOptions} handleChange={setValue('status')}/>
        <OySelect className='mx-2' currentValue={getValue('date')} values={dateOptions} handleChange={setValue('date')}/>
        <label className='font-medium'>sort:</label>
        <OySelect className='ml-2' currentValue={getValue('sort')} values={sortOptions} handleChange={setValue('sort')}/>
      </div>
    </div>
  );
};

export default PrintJobsOverviewFilter;
