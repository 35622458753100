import React from 'react';
import Layout from "../components/Layout";
import OyContainer from "../../../components/OyContainer/OyContainer";
import FulfillmentColumn from "../components/FulfillmentColumn";
import PrintImage from '../images/190404_DeinProduktwirdgedruckt_Illu_1024x1024.png'
// import IncomingGoodsImage from '../images/181024_paketzustellung_bg_highres.png'
// import QualityControlImage from '../images/180806_konfigurator_tutorial_02_designen_highres.png'
// import Questions from "../components/Questions";
import OySearchInput from "../../../components/OySearchInput/OySearchInput";

export interface FulfillmentPageProps {
  printJobsAction?(): void,
  incomingGoodsAction?(): void,
  qualityControlAction?(): void,
  searchOrderNumber(orderNumber: string): void,
}

const FulfillmentPage: React.FC<FulfillmentPageProps> = ({printJobsAction, incomingGoodsAction, qualityControlAction, searchOrderNumber}) => {
  return (
    <Layout>
      <OyContainer className='mb-8'>
        <h1 className='font-bold text-3xl'>Production Service Platform</h1>
        <p className='text-xl'>Nice that you're there! - What do you want to do today?</p>
      </OyContainer>
      <OyContainer className='md:grid md:grid-columns-3 py-8' wrapperClassName='bg-green-100'>
        <FulfillmentColumn icon={PrintImage} title={'1. print jobs'}
                           description={'Look at current print jobs and manage them.'}
                           buttonColor={'purple'}
                           buttonAction={printJobsAction}
        />
        {/*<FulfillmentColumn icon={IncomingGoodsImage} title={'2. incoming goods inspection'}*/}
        {/*                   description={'Check the completeness and quality of the delivered goods.'}*/}
        {/*                   buttonColor={'green'}*/}
        {/*                   buttonAction={incomingGoodsAction}*/}
        {/*/>*/}
        {/*<FulfillmentColumn icon={QualityControlImage} title={'3. quality control'}*/}
        {/*                   description={'Finally check the ready made products and the design to prepare for fulfillment.'}*/}
        {/*                   buttonColor={'blue'}*/}
        {/*                   buttonAction={qualityControlAction}*/}
        {/*/>*/}
      </OyContainer>
      <OyContainer className='max-w-2xl py-8 flex flex-col items-center text-white' wrapperClassName='bg-gray-600'>
        <div className='font-medium'>Global Search</div>
        <OySearchInput id={'orderNumber'} label={false} className='w-64' handleChange={searchOrderNumber} changeOnEnter={true} placeholder={'enter order number'} />
      </OyContainer>
      {/*<OyContainer className='max-w-2xl py-8' wrapperClassName='bg-gray-100'>*/}
      {/*  <Questions/>*/}
      {/*</OyContainer>*/}
    </Layout>
  );
};

export default FulfillmentPage;
