import {OrderDetailsItem} from "@oyoyo/ui/src/apps/production-service-platform/components/OrderDetailItem";
import React from 'react';
import gql from 'graphql-tag';
import {Link, useHistory, useParams} from "react-router-dom";
import { useSubscription } from 'urql';
// import { OrderDetailsPage as Page, OrderDetailsItem } from '@oyoyo/oyoyo-ui';
import { default as Page } from '@oyoyo/ui/src/apps/production-service-platform/pages/order-details';
import {executeQuery} from "../api/hasura";
import Error from '../components/Error';
import {searchOrderNumberQuery} from "./FulfillmentPage";

const orderDetailSubQuery = gql`
  subscription orderDetailSub($id: Int!) {
    printjobitems(where: {shopware_order_number: {_eq: $id}}, order_by: {id: desc}) {
      id
      label
      designId: design_id
      designConfiguration: design_configuration
      printStatus: print_status
      incomingGoodsStatus: incoming_goods_status
      qualityControlStatus: quality_control_status
      barcode
      shopwareOrderDetailId: shopware_order_detail_id
      job {
        id
        name
      }
    }
  }
`;

const handleSubscription = (
    previousPrintjobs: any[] = [],
    response: any,
) => {
  return response.printjobitems;
};

const processStatus = (barcode: string, statusKey: string, item: any, result: any) => {
  if (0 === result[barcode][statusKey]) {
    result[barcode][statusKey] = item[statusKey];
  }
  // switch (item[statusKey]) {
  //   case 3:
  //     result[barcode]['aggregations'][statusKey].reprint++;
  //     break;
  //   case 2:
  //     result[barcode]['aggregations'][statusKey].valid++;
  //     break;
  //   case 1:
  //   default:
  //     result[barcode]['aggregations'][statusKey].new++;
  //     break;
  // }
};

const reduceItems = (items: any): OrderDetailsItem[] => {
  // @ts-ignore
  return Object.values(items.reduce((result: { [key: string]: any }, item: any) => {
    const {barcode, label, shopwareOrderDetailId, designId, designConfiguration, ...rest} = item;
    (result[barcode] = result[barcode] || {
      id: rest.id,
      label,
      barcode,
      shopwareOrderDetailId,
      designId,
      designConfiguration,
      printStatus: 0,
      incomingGoodsStatus: 0,
      qualityControlStatus: 0,
      // aggregations: {
      //   printStatus: {
      //     new: 0,
      //     valid: 0,
      //     reprint: 0,
      //   },
      //   incomingGoodsStatus: {
      //     new: 0,
      //     valid: 0,
      //     reprint: 0,
      //   },
      //   qualityControlStatus: {
      //     new: 0,
      //     valid: 0,
      //     reprint: 0,
      //   },
      // },
      items: [],
    });
    ['printStatus', 'incomingGoodsStatus', 'qualityControlStatus'].forEach(statusKey => processStatus(barcode, statusKey, item, result));
    result[barcode]['items'].push(rest);
    return result;
  }, {})).sort((a: any, b: any) => {
    const lastA = a.items.slice(-1).id;
    const lastB = b.items.slice(-1).id;
    if (lastA < lastB) {
      return -1;
    }
    if (lastA > lastB) {
      return 1;
    }
    return 0;
  })
};

export interface OrderDetailsPageProps {
  // id: number,
}

// export interface StatusItemProps {
//   type: 'print' | 'incoming goods' | 'quality control',
//   // label: string,
//   status: 1 | 2 | 3,
// }
//
// const StatusItem: React.FC<StatusItemProps> = ({type, status}) => {
//   let className = '';
//   switch (status) {
//     case 1:
//       className = 'bg-gray-200';
//       break;
//     case 2:
//       className = 'bg-green-200';
//       break;
//     case 3:
//       className = 'bg-red-200';
//       break;
//   }
//   switch (type) {
//     case 'print':
//       className += ' border-purple-500';
//       break;
//     case 'incoming goods':
//       className += ' border-green-500';
//       break;
//     case 'quality control':
//       className += ' border-blue-500';
//       break;
//   }
//   return (
//       <div title={`${type} status`} className={`flex items-center justify-center p-2 border-t-4 ${className}`}>
//         <StatusIcon type={type} status={status}/>
//       </div>
//   );
// };
//
// const StatusIcon: React.FC<StatusItemProps> = ({status}) => {
//   let statusName = 'unknown';
//   switch (status) {
//     case 1:
//       statusName = 'new';
//       break;
//     case 2:
//       statusName = 'good';
//       break;
//     case 3:
//       statusName = 'bad';
//       break;
//   }
//   return (
//       <div>{statusName}</div>
//   );
// };

const OrderDetailsPage: React.FC<OrderDetailsPageProps> = () => {
  const { id } = useParams();
  const history = useHistory();
  const searchOrderNumber = async (orderNumber: string) => {
    const {printjobitems: [item]} = await executeQuery(searchOrderNumberQuery, {orderNumber}, 'network-only');
    if (item) {
      // console.log(item);
      history.push(`/order-details/${item.shopware_order_number}`);
    }
  };
  const [res] = useSubscription(
      { query: orderDetailSubQuery, variables: { id } },
      handleSubscription,
  );

  if (res.error !== undefined) {
    return <Error>{res.error.message}</Error>;
  }

  if (!res.data) {
    return <div>Loading...</div>;
  }

  const reduced = reduceItems(res.data) as OrderDetailsItem[];
  const itemCount = Object.keys(reduced).length;
  // console.table(res.data);
  // console.table(reduced);
  // console.log(JSON.stringify(reduced));
  // console.log(reduced);

  const result = reduced.reduce((result: any, item: any, i: number) => {
      // console.log(result, item, i);
      if (item.printStatus === 2) {
          result.printStatus--;
      }
      if (item.incomingGoodsStatus === 2) {
          result.incomingGoodsStatus--;
      }
      if (item.qualityControlStatus === 2) {
          result.qualityControlStatus--;
      }
      if (i === itemCount - 1) {
          // last item processed, return status
          if (0 === result.qualityControlStatus) {
              return 'OPEN'; // 0 OPEN
          } else if (0 === result.incomingGoodsStatus) {
              return 'IN_PROCESS_MANUFACTURING'; // 37 IN_PROCESS_MANUFACTURING
          } else if (0 === result.printStatus) {
              return 'IN_PROCESS_PRINTED'; // 38 IN_PROCESS_PRINTED
          }
          return 'IN_PROCESS_PRINT'; // 36 IN_PROCESS_PRINT
      }
      return result;
  }, {
      printStatus: itemCount,
      incomingGoodsStatus: itemCount,
      qualityControlStatus: itemCount,
  });

  // console.table(result);
  // // console.log(Object.keys(reduced).sort());
  //
  const calculatedOrderStatus = result as string;
  const breadcrumbs = [
    {label: 'home', url: '/'},
    {label: 'customer order search', url: `/order-details/${id}`},
  ];
  return (
    <Page breadcrumbs={breadcrumbs} breadcrumbsComponent={Link} searchOrderNumber={searchOrderNumber} orderNumber={id} shopwareOrderStatus={'YO'} calculatedOrderStatus={calculatedOrderStatus} items={reduced} />
  );
};

export default OrderDetailsPage;
