import React from 'react';
import OyBreadcrumbs, {OyBreadcrumbItem} from "../../../components/OyBreadcrumbs/OyBreadcrumbs";
import OySearchInput from "../../../components/OySearchInput/OySearchInput";
// import { SimpleImg } from 'react-simple-img';
// import {ReactComponent as IconNew} from "feather-icons/dist/icons/activity.svg"
// import {ReactComponent as IconGood} from "feather-icons/dist/icons/check-circle.svg"
// import {ReactComponent as IconBad} from "feather-icons/dist/icons/alert-triangle.svg"
import Layout from "../components/Layout";
// import PrintItemStatus from "../components/PrintItemStatus";
import OyContainer from "../../../components/OyContainer/OyContainer";
import OrderDetailItem, {OrderDetailsItem} from "../components/OrderDetailItem";

export interface OrderDetailsPageProps {
  breadcrumbs: OyBreadcrumbItem[],
  breadcrumbsComponent?: string | React.ElementType,
  searchOrderNumber(orderNumber: string): void,
  orderNumber: number,
  shopwareOrderStatus: string,
  calculatedOrderStatus: string,
  items: OrderDetailsItem[],
}

const OrderDetailsPage: React.FC<OrderDetailsPageProps> = ({orderNumber, calculatedOrderStatus, items, breadcrumbs, breadcrumbsComponent, searchOrderNumber}) => {
  return (
    <Layout>
      <OyContainer className='mb-8'>
        <OyBreadcrumbs items={breadcrumbs} component={breadcrumbsComponent} linkProperty={'to'}/>
        <div className='flex justify-between'>
          <h1 className='font-bold text-2xl leading-none my-8'>shopware order #{orderNumber}</h1>
          <OySearchInput id={'orderNumber'} label={'search'} handleChange={searchOrderNumber} changeOnEnter={true} placeholder={'enter order number'} />
        </div>
        <div className='leading-none mb-4'><strong>overall status</strong>: <span className='font-medium'>{calculatedOrderStatus}</span></div>
        {/*<div className='text-xl leading-none mb-4'>shopware order status: <span className='font-medium'>{shopwareOrderStatus}</span></div>*/}
        {/*<div className='text-xl leading-none mb-4'>calculated order status: <span className='font-medium'>{calculatedOrderStatus}</span></div>*/}
      </OyContainer>
      <OyContainer className='py-8' wrapperClassName='bg-gray-100'>
        <div className='p-4 grid grid-gap-4 grid-columns-12 font-medium'>
          <div className='col-span-6'>product name</div>
          <div className='col-span-6'>print</div>
          {/*<div className='col-span-2'>incoming goods</div>*/}
          {/*<div className='col-span-2'>quality control</div>*/}
        </div>
        {items.map(({...item}) => (
          <OrderDetailItem key={item.id} {...item} />
        ))}
      </OyContainer>
      <OyContainer>
        <OyBreadcrumbs items={breadcrumbs} component={breadcrumbsComponent} linkProperty={'to'}/>
      </OyContainer>
    </Layout>
  );
};

export default OrderDetailsPage;
