import React from 'react';
import OyButton from "../../../components/OyButton/OyButton";

export interface FulfillmentColumnProps {
  icon: string,
  title: string,
  description: string,
  buttonColor: 'blue' | 'green' | 'purple',

  buttonAction?(): void,
}

const FulfillmentColumn: React.FC<FulfillmentColumnProps> = ({icon, title, description, buttonColor, buttonAction}) => {
  return (
    <div className='flex flex-col text-center items-center'>
      <div className='w-full max-w-xs relative'>
        <div className='aspect-ratio-square'/>
        <div className='absolute inset-0 flex items-center justify-center'>
          <img src={icon} alt={title}/>
        </div>
      </div>
      <div className='flex-1 my-4'>
        <div className='text-xl mb-4 font-medium'>{title}</div>
        <div>{description}</div>
      </div>
      <OyButton label={'go ahead!'} className='w-40' color={buttonColor} onClick={buttonAction}/>
    </div>
  );
};

export default FulfillmentColumn;
