import React, {useState} from 'react';
import Layout from "../components/Layout";
import OyContainer from "../../../components/OyContainer/OyContainer";
import OyBreadcrumbs, {OyBreadcrumbItem} from "../../../components/OyBreadcrumbs/OyBreadcrumbs";
import PrintJobsOverviewFilter from "../components/PrintJobsOverviewFilter";
import PrintJobsOverviewTable from "../components/PrintJobsOverviewTable";
import OyPagination from "../../../components/OyPagination/OyPagination";
import {PrintJob, PrintJobFilter} from "../types/print-job";
import {OySelectItem} from "../../../components/OySelect/OySelect";
import {AnimatePresence, motion, Variants} from "framer-motion";
import ScanInput from "../components/ScanInput";

const variants: Variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: "30px" },
};

export interface PrintJobsOverviewPageProps {
  title?: string,
  breadcrumbs: OyBreadcrumbItem[],
  breadcrumbsComponent?: string | React.ElementType,
  currentPage: number,
  pagesTotal: number,
  fetching: boolean,
  scan: boolean,
  printJobs: PrintJob[],
  openDetails(id: number): void,
  downloadArchive(id: number): void,
  switchPage(page: OySelectItem): void,
  setFilter(filter: PrintJobFilter): void,
  validateBarcode(value: string | number): Promise<boolean>,
}

const PrintJobsOverviewPage: React.FC<PrintJobsOverviewPageProps> = ({title, breadcrumbs, breadcrumbsComponent, printJobs, currentPage, pagesTotal, fetching, openDetails, downloadArchive, switchPage, setFilter, validateBarcode, scan}) => {
  const [isScanning, setIsScanning] = useState(scan);
  return (
    <Layout>
      <OyContainer>
        <OyBreadcrumbs items={breadcrumbs} component={breadcrumbsComponent} linkProperty={'to'}/>
        <h1 className='text-2xl font-bold text-purple-500 my-8'>{title}</h1>
        <PrintJobsOverviewFilter handleChange={setFilter} isScanning={isScanning} setIsScanning={setIsScanning}/>
      </OyContainer>
      <OyContainer className='py-8' wrapperClassName='bg-gray-100'>
        {fetching && (<div>Loading...</div>)}
        {!fetching && (<PrintJobsOverviewTable items={printJobs} handleOnClick={openDetails} downloadArchive={downloadArchive}/>)}
      </OyContainer>
      <OyContainer className='flex justify-between'>
        <OyBreadcrumbs items={breadcrumbs} component={breadcrumbsComponent} linkProperty={'to'}/>
        <OyPagination currentPage={currentPage} pagesTotal={pagesTotal} handleChange={switchPage}/>
      </OyContainer>
      <div className='fixed bottom-0 w-full pointer-events-none flex items-center justify-center'>
        <AnimatePresence>
          {isScanning && (
            <motion.div
              key={'scan'}
              variants={variants}
              initial={"closed"}
              animate={"open"}
              exit={"closed"}
            >
              <ScanInput validateInput={validateBarcode} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Layout>
  );
};

PrintJobsOverviewPage.defaultProps = {
  title: 'print jobs overview',
};

export default PrintJobsOverviewPage;
