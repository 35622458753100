import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
// import {useTransition, animated} from 'react-spring';
import {motion, AnimatePresence, Variants} from "framer-motion"
import {useLockBodyScroll} from 'react-use';
import Layout from "../components/Layout";
import OyContainer from "../../../components/OyContainer/OyContainer";
import OyBreadcrumbs, {OyBreadcrumbItem} from "../../../components/OyBreadcrumbs/OyBreadcrumbs";
import OyButton from "../../../components/OyButton/OyButton";
import {ReactComponent as IconDownload} from "feather-icons/dist/icons/download-cloud.svg"
import PrintJobGridItem from "../components/PrintJobGridItem";
import OySelect, {OySelectItem} from "../../../components/OySelect/OySelect";
// import OyInput from "../../../components/OyInput/OyInput";
import ReprintJobList from "../components/ReprintJobList";
import {PrintJob} from "../types/print-job";
import ScanInput from "../components/ScanInput";
import {PrintJobItem} from "../types/print-job-item";
import ScanModal from "../components/ScanModal";

export interface PrintJobsDetailPageProps {
  title: string,
  breadcrumbs: OyBreadcrumbItem[],
  breadcrumbsComponent?: string | React.ElementType,
  printJob?: PrintJob,
  currentItem?: PrintJobItem,
  setCurrentItem: Dispatch<SetStateAction<any>>,
  updateStatus(status: OySelectItem): void,
  downloadArchive(): void,
  printOverview(): void,
  printOverviewDocx(): void,
  ordersDocx(): void,
  ordersXlsx(): void,
  barcodeOverview(): void,
  confirmItem(): void,
  downloadItem(): void,
  reprintItem(): void,
  validateBarcode(value: string | number): Promise<boolean>,
  resetItem(jobId: number, itemId: number): void,
  createReprintOrder(): void,
}

const statusOptions = [{value: 1, label: 'new'}, {value: 2, label: 'in progress'}, {value: 3, label: 'done'}];

const variants: Variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: "30px" },
};

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    }
  }
};

const PrintJobsDetailPage: React.FC<PrintJobsDetailPageProps> = ({title, breadcrumbs, breadcrumbsComponent, printJob, updateStatus, currentItem, setCurrentItem, downloadArchive, printOverview, printOverviewDocx, ordersDocx, ordersXlsx, barcodeOverview, confirmItem, downloadItem, reprintItem, validateBarcode, resetItem, createReprintOrder}) => {
  const [isScanning, setIsScanning] = useState(false);
  // const transitions = useTransition(isScanning, null, {
  //   from: { opacity: 0, transform: 'translate3d(0,30px,0)' },
  //   enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  //   leave: { opacity: 0, transform: 'translate3d(0,30px,0)' },
  // });
  // const modalTransitions = useTransition(currentItem, null, {
  //   from: { opacity: 0, transform: 'translate3d(0,30px,0)' },
  //   enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  //   leave: { opacity: 0, transform: 'translate3d(0,30px,0)' },
  // });
  useLockBodyScroll(!!currentItem);
  const itemsElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (printJob && currentItem && itemsElement.current) {
      const index = printJob.items.findIndex((item: PrintJobItem) => item.barcode === currentItem.barcode);
      if (index !== -1) {
        const item = itemsElement.current.querySelector(`:nth-child(${index})`);
        if (item) {
          setTimeout(() => {
            item.scrollIntoView({behavior: 'smooth', block: 'start'})
          }, 50)
        }
      }
    }
  }, [itemsElement, currentItem]);

  const closeModal = () => { setCurrentItem(undefined) };
  // const handleChange: void = (value: OySelectItem) => {
  //   updateStatus(value.value as number);
  // };
  return (
    <Layout>
      <OyContainer>
        <OyBreadcrumbs items={breadcrumbs} component={breadcrumbsComponent} linkProperty={'to'}/>
        {printJob && (
          <>
            <div className='flex justify-between my-8'>
              <h1 className='text-2xl font-bold text-purple-500'>{printJob.name}</h1>
              <OyButton color={'blue'} label={'Download PDF files'} icon={<IconDownload className={'w-6 h-6 ml-1'}/>}
                        iconPosition={'right'} onClick={downloadArchive}/>
              {printJob.version === 1 && (
                <OyButton color={'gray'} label={'print this overview'} onClick={printOverview}/>
              )}
              {(printJob.version === 2) && (
                <>
                  <OyButton color={'gray'} label={'overview PDF'} onClick={printOverview}/>
                  <OyButton color={'gray'} label={'barcode DOCX'} onClick={barcodeOverview}/>
                  <OyButton color={'gray'} label={'overview DOCX'} onClick={printOverviewDocx}/>
                  <OyButton color={'gray'} label={'orders DOCX'} onClick={ordersDocx}/>
                  <OyButton color={'gray'} label={'orders XLSX'} onClick={ordersXlsx}/>
                </>
              )}
              {(printJob.version === 3) && (
                <>
                  {/*<OyButton color={'gray'} label={'overview PDF'} onClick={printOverview}/>*/}
                  {/*<OyButton color={'gray'} label={'barcode DOCX'} onClick={barcodeOverview}/>*/}
                  <OyButton color={'gray'} label={'overview DOCX'} onClick={printOverviewDocx}/>
                  <OyButton color={'gray'} label={'orders DOCX'} onClick={ordersDocx}/>
                  <OyButton color={'gray'} label={'orders XLSX'} onClick={ordersXlsx}/>
                </>
              )}
              <OyButton color={'purple'} label={`${isScanning ? 'stop' : 'start'} scanning`} onClick={() => setIsScanning(!isScanning)}/>
            </div>
            <div className='grid md:grid-columns-3 md:grid-gap-4 lg:grid-columns-6'>
              <div className='flex flex-col'>
                <div className='font-bold truncate'>print order number</div>
                <div className='py-1'>{printJob.name}</div>
              </div>
              <div className='flex flex-col'>
                <div className='font-bold truncate'>date</div>
                <div className='py-1'>{printJob.createdAt}</div>
              </div>
              <div className='flex flex-col'>
                <div className='font-bold truncate'>status</div>
                <OySelect currentValue={statusOptions.find((option: OySelectItem) => option.value === printJob.status)} values={statusOptions} handleChange={updateStatus}/>
              </div>
              <div className='flex flex-col'>
                <div className='font-bold truncate'>print items</div>
                <div className='py-1'>{printJob.itemsPrinted}/{printJob.itemsTotal}</div>
              </div>
              {/*<div className='flex flex-col'>*/}
              {/*  <div className='font-bold truncate'>delivery date</div>*/}
              {/*  <OyInput currentValue={'2019-07-26'} type={'date'} handleChange={() => {}}/>*/}
              {/*</div>*/}
              {/*<div className='flex flex-col'>*/}
              {/*  <div className='font-bold truncate'>tracking IDs</div>*/}
              {/*  <OyInput currentValue={''} handleChange={() => {}}/>*/}
              {/*</div>*/}
            </div>
          </>
        )}
      </OyContainer>
      <OyContainer className='py-8' wrapperClassName='bg-gray-100'>
        <div className='grid sm:grid-columns-2 md:grid-columns-3 lg:grid-columns-4 grid-gap-4' ref={itemsElement}>
          {typeof printJob !== "undefined" && printJob.items.map(printJobItem => (
            <PrintJobGridItem key={printJobItem.id} item={printJobItem} onClick={setCurrentItem}/>
          ))}
        </div>
      </OyContainer>
      {printJob && <ReprintJobList className='mt-8' titleClassName='text-purple-500' title={`${title.replace(/_reprint_\d+/, '')}_reprint_${1 + (printJob.reprintCount || 0)}`} printJobItems={printJob.items.filter(item => item.status === 3)} onDelete={resetItem} onReprint={createReprintOrder}/>}
      <OyContainer>
        <OyBreadcrumbs items={breadcrumbs} component={breadcrumbsComponent} linkProperty={'to'}/>
      </OyContainer>
      <div className='fixed bottom-0 w-full pointer-events-none flex items-center justify-center'>
        <AnimatePresence>
          {isScanning && (
            <motion.div
              key={'scan'}
              variants={variants}
              initial={"closed"}
              animate={"open"}
              exit={"closed"}
            >
              <ScanInput validateInput={validateBarcode} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/*{*/}
      {/*  transitions.map(({ item, key, props }) =>*/}
      {/*    item && (*/}
      {/*      <div key={key} className='fixed bottom-0 w-full pointer-events-none flex items-center justify-center'>*/}
      {/*        <animated.div style={props}><ScanInput /></animated.div>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  )*/}
      {/*}*/}
      {/*{*/}
      {/*  modalTransitions.map(({ item, key, props }) =>*/}
      {/*    item && (*/}
      {/*      <div key={key} className='fixed bottom-0 w-full pointer-events-none flex items-center justify-center'>*/}
      {/*        <animated.div style={props}><ScanInput /></animated.div>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  )*/}
      {/*}*/}
      <AnimatePresence>
        {typeof currentItem !== 'undefined' && (
          <ScanModal reprintItem={reprintItem} item={currentItem} downloadItem={downloadItem} closeModal={closeModal} confirmItem={confirmItem}/>
        )}
      </AnimatePresence>
    </Layout>
  );
};

// PrintJobsDetailPage.defaultProps = {
// };

export default PrintJobsDetailPage;
