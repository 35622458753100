import React from 'react';
import clsx from "clsx";
import OySelect, {OySelectItem} from "../OySelect/OySelect";
import {ReactComponent as IconPrev} from "feather-icons/dist/icons/chevron-left.svg"
import {ReactComponent as IconNext} from "feather-icons/dist/icons/chevron-right.svg"

export interface OyPaginationProps {
  className?: string,
  currentPage: number,
  pagesTotal: number,

  handleChange(page: OySelectItem): void
}

const OyPagination: React.FC<OyPaginationProps> = ({className, currentPage, pagesTotal, handleChange}) => {
  const values: OySelectItem[] = Array.from({length: pagesTotal}, (v, k) => { return { value: k + 1, label: `${k + 1}` }});
  const prevPage = () => {
    const p = currentPage - 1;
    handleChange({label: p as unknown as string, value: p});
  }
  const nextPage = () => {
    const p = currentPage + 1;
    handleChange({label: p as unknown as string, value: p});
  }
  return (
    <div className={clsx('flex items-center', className)}>
      <button disabled={currentPage === 1} className='p-2 disabled:opacity-50 disabled:cursor-not-allowed' onClick={prevPage}>
        <IconPrev className='w-4 h-4'/>
      </button>
      Page
      <OySelect className='mx-2' currentValue={values[currentPage - 1]} values={values}
                handleChange={handleChange}/>
      of {pagesTotal}
      <button disabled={currentPage === pagesTotal} className='p-2 disabled:opacity-50 disabled:cursor-not-allowed' onClick={nextPage}>
        <IconNext className='w-4 h-4'/>
      </button>
    </div>
  );
};

OyPagination.defaultProps = {
  className: '',
};

export default OyPagination;
