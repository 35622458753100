import React, {MutableRefObject, RefObject} from 'react';
import OyInput from "../OyInput/OyInput";
import {ReactComponent as IconSearch} from "feather-icons/dist/icons/search.svg"
import clsx from "clsx";

export interface OyInputProps {
  id: string,
  label?: string | false,
  placeholder?: string,
  className?: string,
  inputClassName?: string,
  currentValue?: string | number | undefined,
  inputRef?: MutableRefObject<HTMLInputElement | null>,
  changeOnEnter?: boolean,
  disabled?: boolean,

  handleChange(value: string | number): void,
}

const OySearchInput: React.FC<OyInputProps> = ({id, inputRef, label, className, inputClassName, currentValue, handleChange, changeOnEnter, disabled, placeholder}) => {
  return (
    <div className={clsx('flex items-center', className)}>
      { label && (<label className='font-medium' htmlFor={id}>search:</label>)}
      <OyInput id={id} inputRef={inputRef} className={clsx({'mx-2': label, 'mr-2': !label}, inputClassName)} currentValue={currentValue} handleChange={handleChange} changeOnEnter={changeOnEnter} disabled={disabled} placeholder={placeholder}/>
      <IconSearch className='w-6 h-6'/>
    </div>
  );
};

OySearchInput.defaultProps = {
  className: '',
  label: 'search',
  placeholder: '',
  inputClassName: '',
  currentValue: '',
  changeOnEnter: false,
};

export default OySearchInput;
