import Layout from "@oyoyo/ui/src/apps/production-service-platform/components/Layout";
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import React from 'react';

const FabricPatternsPage: React.FC = () => {
  return (
    <Layout>
      <OyContainer className='flex flex-col justify-center'>
        Fabric Patterns
      </OyContainer>
    </Layout>
  );
};

export default FabricPatternsPage;
