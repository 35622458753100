import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className='flex items-center justify-center h-24'>
      © textilwerk 2018 till today | all rights reserved
    </footer>
  );
};

export default Footer;
