import {PrintJob} from "@oyoyo/ui/src/apps/production-service-platform/types/print-job";
import {PrintJobItem} from "@oyoyo/ui/src/apps/production-service-platform/types/print-job-item";
import {OySelectItem} from "@oyoyo/ui/src/components/OySelect/OySelect";
import React, {useState} from 'react';
import gql from 'graphql-tag';
import axios, {AxiosResponse} from 'axios';
import { useSubscription, useMutation } from 'urql';
import { Link, useParams, useHistory, Prompt } from 'react-router-dom';
// import { PrintJobsDetailPage as Page, PrintJob, PrintJobItem, OySelectItem } from '@oyoyo/oyoyo-ui';
import { default as Page } from '@oyoyo/ui/src/apps/production-service-platform/pages/print-jobs-detail';
import { format, parseISO } from 'date-fns';
import Error from '../components/Error';
import {convertPrintjobItem} from "../api/convert";
import {setPrintjobItemStatus} from "../api/nestjs";
import {useSessionStorage} from "react-use";
import { useToasts } from 'react-toast-notifications';

const printJobDetailSubQuery = gql`
  subscription printJobDetailSub($id: Int!) {
    printjobs(where: {id: {_eq: $id}}) {
      id
      name
      material
      created_at
      status
      version
      items(order_by: {id: asc}) {
        id
        label
        design_id
        barcode
        print_status
        design_configuration
        job {
          id
          name
          material
        }
        productVariantId
      }
      reprints: reprints_aggregate {
        aggregate {
          count
        }
      }
      parent {
        reprints: reprints_aggregate {
          aggregate {
            count
          }
        }
      }
      orderstats {
        files: allOrderData(path: "files")
      }
    }
  }
`;

const updatePrintJobStatusMutation = gql`
  mutation updatePrintJobStatus($id: Int!, $status: Int!) {
    update_printjobs(where: {id: {_eq: $id}}, _set: {status: $status}) {
      returning {
        id
        status
      }
    }
  }
`;

// const updatePrintjobItemPrintStatusMutation = gql`
//   mutation updatePrintjobItemPrintStatus($id: Int!, $status: Int!) {
//     update_printjobitems(where: {id: {_eq: $id}}, _set: {print_status: $status}) {
//       returning {
//         id
//         print_status
//       }
//     }
//   }
// `;

interface PrintJobsResponse {
  printjobs: PrintJobsEntry[];
}

interface PrintJobsEntryItem {
  id: number;
  label: string;
  design_id: string;
  barcode: string;
  print_status: number;
  design_configuration: {
    texture: string,
    backgroundColor: string,
  };
}

interface PrintJobsEntry {
  id: number;
  name: string;
  material: number;
  created_at: string;
  status: number;
  version: number;
  items: PrintJobsEntryItem[];
  reprints: {
    aggregate: {
      count: number
    }
  }
  parent?: {
    reprints: {
      aggregate: {
        count: number
      }
    }
  }
  orderstats?: {
    files: {
      name: string,
      thumbnail: string,
    }[]
  }
}

const convert = (jobs: PrintJobsEntry[]): PrintJob[] => {
  return jobs.map(job => {
    return {
      id: job.id,
      name: job.name,
      material: job.material,
      createdAt: format(parseISO(job.created_at), 'yyyy-MM-dd'),
      status: job.status,
      version: job.version,
      itemsPrinted: job.items.reduce((acc: number, cur: PrintJobsEntryItem) => { return cur.print_status === 2 ? acc + 1 : acc }, 0),
      itemsTotal: job.items.length,
      itemsRescheduled: 0,
      deliveryDate: undefined,
      trackingCodes: [],
      items: job.items.map(item => convertPrintjobItem(item, 'print_status', job?.orderstats?.files)),
      reprintCount: job.parent ? job.parent.reprints.aggregate.count : job.reprints.aggregate.count,
    };
  });
};

const handleSubscription = (
  previousPrintjobs: PrintJobsEntry[] = [],
  response: PrintJobsResponse,
) => response.printjobs;


export interface PrintJobsDetailPageProps {
  // id: number,
}

const PrintJobsDetailPage: React.FC<PrintJobsDetailPageProps> = () => {
  // const route = useCurrentRoute();
  // console.log(route);
  const { id } = useParams();

  const [barcode, setBarcode] = useSessionStorage('barcode', '', true);

  const history = useHistory();
  const { addToast } = useToasts();
  const [currentItem, setCurrentItem] = useState<PrintJobItem | undefined>(undefined);
  const [hasOpenReprints, setOpenReprints] = useState<boolean>(false);

  const [res] = useSubscription(
    { query: printJobDetailSubQuery, variables: { id } },
    handleSubscription,
  );

  const [, executeMutation] = useMutation(updatePrintJobStatusMutation);
  const setPrintJobStatus = (id: number, status: OySelectItem) => {
    console.log(id, status);
    executeMutation({id, status: status.value}).then(r => console.log(r));
  };

  // const [mutItemRes, executeItemMutation] = useMutation(updatePrintjobItemPrintStatusMutation);

  const downloadArchive = () => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/archive`);
  };

  const printOverview = () => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/overview/pdf`);
  };

  const barcodeOverview = () => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/barcodes/docx`);
  };

  const printOverviewDocx = () => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/overview/docx`);
  }

  const ordersDocx = () => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/orders/docx`);
  }

  const ordersXlsx = () => {
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/orders/xlsx`);
  }

  const confirmItem = () => {
    console.log('confirmItem', currentItem);
    if (typeof currentItem !== "undefined") {
      const newStatus = 2 === currentItem.status ? 1 : 2;
      setPrintjobItemStatus(id, currentItem.id, newStatus, 'print_status').then(() => {
        setCurrentItem(undefined);
        addToast(`Item ${currentItem?.barcode} ${1 === newStatus ? 'unconfirmed' : 'confirmed'}`, { appearance: 'success', autoDismiss: true });
        history.push('/print-jobs/scan');
      });
      // // @ts-ignore
      // executeItemMutation({ id: currentItem.id, status: 2 })
      //     .then(() => {
      //       setCurrentItem(undefined);
      //     })
      // ;
    }
  };

  const downloadItem = () => {
    // console.log('downloadItem', currentItem);
    // console.log('downloadItem', `${process.env.REACT_APP_API_NESTJS}/printjob/${currentItem.id}/pdf`);
    window.open(`${process.env.REACT_APP_API_NESTJS}/printjob/${id}/item/${currentItem?.id}/pdf`);
  };

  const reprintItem = () => {
    console.log('reprintItem', currentItem);
    if (typeof currentItem !== "undefined") {
      setPrintjobItemStatus(id, currentItem.id, 3, 'print_status').then(() => {
          setCurrentItem(undefined);
          setOpenReprints(true);
      });
      // // @ts-ignore
      // executeItemMutation({ id: currentItem.id, status: 3 })
      //     .then(() => {
      //       setCurrentItem(undefined);
      //     })
      // ;
    }
  };

  const resetItem = (jobId: number, itemId: number) => {
    // console.log('resetItem', id, jobId, itemId);
    setPrintjobItemStatus(id, itemId, 1, 'print_status');
    // executeItemMutation({ id: itemId, status: 1 });
  };

  if (res.error !== undefined) {
    return <Error>{res.error.message}</Error>;
  }

  const [printJob] = res.data === undefined ? [undefined] : convert(res.data);
  const title = printJob ? printJob.name : 'loading...';
  console.log(printJob);
  // @ts-ignore
  window.printJob = printJob;

  const validateBarcode = (barcode: string): Promise<boolean> => {
    if (printJob) {
      const item = printJob.items.find((item: PrintJobItem) => item.barcode === barcode);
      if (item) {
        setCurrentItem(item);
        return Promise.resolve(true);
      }
    }
    return Promise.reject(false);
  };

  if (barcode && printJob) {
    validateBarcode(barcode);
    setBarcode('');
  }

  const createReprintOrder = () => {
    if (!printJob) {
      return;
    }
    axios.post<AxiosResponse<object>>(`${process.env.REACT_APP_API_NESTJS}/printjob/${printJob.id}/reprint`, {
      items: printJob.items.filter(item => item.status === 3).map(({id}) => id),
    })
        .then(response => {
          console.log(response);
          setOpenReprints(false);
          history.push('/print-jobs');
        })
        .catch(error => console.error)
    ;
  };

  const breadcrumbs = [
    {label: 'home', url: '/'},
    {label: 'print jobs overview', url: '/print-jobs'},
    {label: title, url: `/print-jobs/${id}`},
  ];

  // console.log(`hasOpenReprints: ${hasOpenReprints ? 'y' : 'n'}`)

  return (
    <>
      <Page breadcrumbs={breadcrumbs} breadcrumbsComponent={Link} printJob={printJob}
            updateStatus={(newStatus) => setPrintJobStatus(id, newStatus)} title={title}
            currentItem={currentItem} setCurrentItem={setCurrentItem}
            confirmItem={confirmItem} downloadItem={downloadItem} reprintItem={reprintItem} resetItem={resetItem}
            validateBarcode={validateBarcode} createReprintOrder={createReprintOrder}
            downloadArchive={downloadArchive} printOverview={printOverview} printOverviewDocx={printOverviewDocx} ordersDocx={ordersDocx} ordersXlsx={ordersXlsx} barcodeOverview={barcodeOverview}
      />
      <Prompt
        when={hasOpenReprints}
        message={location =>
          `You selected some files for reprint, but have not yet created the reprint order. Are you sure to go to ${location.pathname}?`
        }
      />
    </>
  );
};

export default PrintJobsDetailPage;
