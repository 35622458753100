import {Fragment} from "react";
import * as React from "react"

interface PrintJobDataProps {
  printjobData: any
}

const seatCushionSizeMap = {
  'OY-BA-120x48': 'OY-BA-120x48',
  'OY-BA-150x48': 'OY-BA-150x48',
  'OY-BO-50x50': 'OY-BO-50x50',
  'OY-SK-40x40-122': 'OY-SK-01_40x40_S (Quadratisch)',
  'OY-SK-40x40-123': 'OY-SK-01_40x40_PS (mit Steppung)',
  'OY-SK-40x40-124': 'OY-SK-01_40x40_BS (mit Bändern)',
  'OY-SK-40x40-125': 'OY-SK-01_40_R (Rund)',
}

const PrintJobData: React.FC<PrintJobDataProps> = ({ printjobData }) => {
  return (
    <div>
      <h2 className='text-2xl font-medium'>print job for {printjobData.date}</h2>
      <h3 className='text-lg font-medium'>processed order numbers</h3>
      <div>{printjobData.processedOrderNumbers.join(', ')}</div>
      <h3 className='text-lg font-medium'>orders containing filling cussion products</h3>
      <ul>
        {Object.keys(printjobData.fillingCussionOrders).map((orderNumber) => (
          <li key={orderNumber}>
            <strong>{`${orderNumber}`}</strong>
            <ul>
              {Object.keys(printjobData.fillingCussionOrders[orderNumber]).map((productOrderNumber) => (
                <li key={`${orderNumber}-${productOrderNumber}`}>{`${printjobData.fillingCussionOrders[orderNumber][productOrderNumber]}x ${productOrderNumber}`}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <h3 className='text-lg font-medium'>inventory details</h3>
      <ul>
        {printjobData.inventoryDetails.map((detail, i) => (<li key={i}>{detail}</li>))}
      </ul>
      <h3 className='text-lg font-medium'>material details</h3>
      <ul>
        {Object.keys(printjobData.qm).map((material) => (
          <li key={material}>{`${material}: ${printjobData.qm[material].toFixed(3)} m²`}</li>
        ))}
        <li>total: {printjobData.qmTotal.toFixed(3)} m²</li>
      </ul>
      <h3 className='text-lg font-medium'>filling cushions</h3>
      <ul>
        {Object.keys(printjobData.fillings).map((size) => {
          return (
            <Fragment key={size}>
              {Object.keys(printjobData.fillings[size]).map((material) => (
                <li key={material}>{`${printjobData.fillings[size][material]}x filling cushions for ${size} ${material}`}</li>
              ))}
            </Fragment>
          )
        })}
      </ul>
      <h3 className='text-lg font-medium'>seat/floor/bench cushion fillings</h3>
      <ul>
        {Object.keys(printjobData.seatCushionFillings).map((size) => {
          return (
            <Fragment key={size}>
              {Object.keys(printjobData.seatCushionFillings[size]).map((material) => (
                <li key={material}>{`${printjobData.seatCushionFillings[size][material]}x filling cushions for ${size} ${material}`}</li>
              ))}
            </Fragment>
          )
        })}
      </ul>
      <h3 className='text-lg font-medium'>poster bars</h3>
      <ul>
        {Object.keys(printjobData.posters).map((size) => {
          return (
            <Fragment key={size}>
              {Object.keys(printjobData.posters[size]).map((color) => (
                <li key={color}>{`${printjobData.posters[size][color]}x textile poster bar for ${size} ${color}`}</li>
              ))}
            </Fragment>
          )
        })}
      </ul>
      <h3 className='text-lg font-medium'>naturals goody bags</h3>
      <ul>
        {Object.keys(printjobData.bags).map((orderNumber) => {
          return (
            <li key={orderNumber}>
              {`${printjobData.bags[orderNumber].quantity}x goody bag(s) (${printjobData.bags[orderNumber].colors.join(', ')}) for order number ${orderNumber}`}
            </li>
          )
        })}
      </ul>
      <h3 className='text-lg font-medium'>print jobs</h3>
      <ul>
        {Object.keys(printjobData.printJobs).map((material) => (
          <li key={material}>
            {printjobData.printJobs[material].name} containing {printjobData.printJobs[material].items.length} items
            {/*<div>*/}
            {/*  {printjobData.printJobs[material].items.filter(({designId}) => !designId).map((item, i) => (*/}
            {/*    <div key={i}>{item.label}</div>*/}
            {/*  ))}*/}
            {/*</div>*/}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PrintJobData
