import axios, {AxiosResponse} from "axios";

export const setPrintjobItemStatus = async (printJobId: number, printJobItemId: number, status: 1 | 2 | 3, field: 'print_status' | 'quality_control_status' | 'incoming_goods_status') => {
    // return new Promise((resolve, reject) => {});
    let prefix;
    switch (field) {
        case 'print_status':
            prefix = 'print';
            break;
        case 'quality_control_status':
            prefix = 'quality-control';
            break;
        case 'incoming_goods_status':
            prefix = 'incoming-goods';
            break;
        default:
            throw new Error(`Unknown field: "${field}"`);
    }
    return axios.patch<AxiosResponse<object>>(`${process.env.REACT_APP_API_NESTJS}/printjob/${printJobId}/item/${printJobItemId}/status/${prefix}/${status}`);
};

export const createPrintJobs = async (date: string, dry: boolean) => {
  return axios.post<AxiosResponse<object>>(`${process.env.REACT_APP_API_NESTJS}/printjob/create/${date}`, {dry});
}

export const getOrderStatsOverview = async () => {
  return axios.get<AxiosResponse<object>>(`${process.env.REACT_APP_API_NESTJS}/printjob/order-stats/overview`);
}
