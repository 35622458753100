import React from 'react';

const Error: React.FC = ({children}) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default Error;
