import {PrintJobItem} from "./print-job-item";
import {OySelectItem} from "../../../components/OySelect/OySelect";

export interface PrintJob {
  id: number,
  name: string,
  material?: number,
  reprintCount?: number,
  createdAt: string,
  status: number,
  version: number,
  itemsPrinted: number,
  itemsTotal: number,
  itemsRescheduled?: number,
  deliveryDate?: string,
  trackingCodes?: number[],
  items: PrintJobItem[],
}

export interface PrintJobFilter {
  search?: string,
  material?: OySelectItem,
  status?: OySelectItem,
  date?: OySelectItem,
  sort?: OySelectItem,
}

export const PrintjobStatus = {
  NEW: 1,
  IN_PROGRESS: 2,
  DONE: 3,
};

export const printjobStatusLabel = (status: number) => {
  switch (status) {
    case PrintjobStatus.NEW:
      return 'new';
    case PrintjobStatus.IN_PROGRESS:
      return 'in progress';
    case PrintjobStatus.DONE:
      return 'done';
    default:
      return 'unknown'
  }
};
