import React from 'react';
import clsx from "clsx";
import { SimpleImg } from 'react-simple-img';
import {motion, Variants} from "framer-motion"
import {ReactComponent as IconInfo} from "feather-icons/dist/icons/info.svg"
import {PrintJobItem} from "../types/print-job-item";
import OyBarcode from "../../../components/OyBarcode/OyBarcode";
// import {OyBarcodeClass as OyBarcode} from "../../../components/OyBarcode/OyBarcode";

export interface PrintJobGridItemProps {
  item: PrintJobItem,
  onClick(item: PrintJobItem): void,
}

const variants: Variants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
};

const PrintJobGridItem: React.FC<PrintJobGridItemProps> = ({item, onClick}) => {
  // console.log(item)
  const {designUrl, designId, barcode, label, status, designConfiguration} = item;
  const handleOnClick = () => {
    onClick(item);
  };
  const backgroundStyle: {backgroundColor?: string} = {};
  if (designConfiguration && !designConfiguration.texture && designConfiguration.backgroundColor) {
    backgroundStyle.backgroundColor = designConfiguration.backgroundColor;
  }
  return (
    <div className={clsx('flex flex-col text-center items-center bg-white border-8 p-2', {
      'border-gray-200 hover:border-gray-300': status === 1,
      'border-green-200 hover:border-green-300': status === 2,
      'border-red-200 hover:border-red-300': status === 3,
    })}>
      <button className='self-start text-blue-500 my-2' onClick={handleOnClick}>
        <IconInfo className='w-6 h-6'/>
      </button>
      <div className='w-full max-w-xs relative mb-2'>
        <div className='aspect-ratio-square' style={backgroundStyle}/>
        {(designConfiguration && designConfiguration.texture) && (
          <div className='absolute inset-0 flex items-center justify-center overflow-hidden'>
            <SimpleImg src={designUrl} />
          </div>
        )}
      </div>
      <OyBarcode className={'w-full'} value={barcode}/>
      <div className='text-xs mb-2'>{barcode}</div>
      <div className='text-xs'>{label}</div>
    </div>
  );
};

// PrintJobGridItem.defaultProps = {
// };

export default PrintJobGridItem;
