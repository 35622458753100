import React, {MutableRefObject, RefObject, SyntheticEvent, useEffect, useState} from 'react';
import clsx from "clsx";

export interface OyInputProps {
  id?: string,
  className?: string,
  placeholder?: string,
  type?: 'text' | 'date' | 'email' | 'password',
  currentValue: string | number | undefined,
  autoFocus?: boolean,
  inputRef?: MutableRefObject<HTMLInputElement | null>,
  changeOnEnter?: boolean,
  disabled?: boolean,

  handleChange(value: string | number): void,
}

const OyInput: React.FC<OyInputProps> = ({id, inputRef, type, className, currentValue, handleChange, changeOnEnter, autoFocus, disabled, placeholder}) => {
  const [value, setValue] = useState(currentValue);
  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);
  const onKeyUp = (event: any) => {
    if (changeOnEnter && 'Enter' === event.key) {
      // @ts-ignore
      handleChange(event.target.value);
    }
  };
  const onChange = (event: SyntheticEvent) => {
    // handleChange(event.target.value);
    // @ts-ignore
    setValue(event.target.value);
    if (!changeOnEnter) {
      // @ts-ignore
      handleChange(event.target.value);
    }
  };
  return (
    <input id={id} ref={inputRef} type={type} value={value} onKeyUp={onKeyUp} onChange={onChange} autoFocus={autoFocus} disabled={disabled} placeholder={placeholder}
           className={clsx('flex-1 appearance-none border border-gray-400 hover:border-gray-500 rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none', {
             'disabled:text-gray-500 disabled:bg-gray-200': disabled,
           }, className)}/>
  );
};

OyInput.defaultProps = {
  type: 'text',
  className: '',
  autoFocus: false,
  changeOnEnter: false,
};

export default OyInput;
