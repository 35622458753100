import React from 'react';
// import { useLoadingRoute } from 'react-navi'
import { Provider } from 'urql';
import { client } from './api/hasura';

const Layout: React.FC = ({children}) => {
  // const loadingRoute = useLoadingRoute();
  return (
    <Provider value={client}>
      <main>
        {children}
      </main>
    </Provider>
  );
};

export default Layout;
