import React from 'react';
import clsx from "clsx";
import {ReactComponent as IconNew} from "feather-icons/dist/icons/activity.svg"
import {ReactComponent as IconGood} from "feather-icons/dist/icons/check-circle.svg"
import {ReactComponent as IconBad} from "feather-icons/dist/icons/alert-triangle.svg"

export interface PrintItemStatusProps {
  type: 'print' | 'incoming-goods' | 'quality-control',
  status?: 1 | 2 | 3,
  outline?: boolean,
}

const StatusIcon: React.FC<{status: 1 | 2 | 3 | undefined}> = ({status}) => {
  const className = 'w-6 h-6';
  switch (status) {
    case 3:
      return <IconBad className={className}/>;
    case 2:
      return <IconGood className={className}/>;
    case 1:
    default:
      return <IconNew className={className}/>;
  }
};

const PrintItemStatus: React.FC<PrintItemStatusProps> = ({type, status, outline}) => {
  let color = '';
  switch (type) {
    case 'print':
      color = 'purple-500';
      break;
    case 'incoming-goods':
      color = 'green-500';
      break;
    case 'quality-control':
      color = 'blue-500';
      break;
  }
  return (
    <div className={clsx('rounded p-1 flex justify-center', `${outline ? 'border' : 'bg'}-${color}`, `text-${outline ? color : 'white'}`, {
      'text-white': !outline,
      'border': outline,
    })}>
      <StatusIcon status={status}/>
    </div>
  );
};

PrintItemStatus.defaultProps = {
  status: 1,
  outline: false,
};

export default PrintItemStatus;
