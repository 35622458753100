import React, { useRef, useEffect } from 'react';
import bwipjs from 'bwip-js';

export interface OyBarcodeProps {
  className?: string,
  bcid?: string,
  scale?: number,
  height?: number,
  value: string,
}

// export class OyBarcodeClass extends React.Component<OyBarcodeProps> {
//   private readonly element: React.RefObject<HTMLCanvasElement>;
//
//   static defaultProps = {
//     className: '',
//     bcid: 'code128',
//     scale: 3,
//     height: 10,
//   };
//
//   constructor(props: OyBarcodeProps) {
//     super(props);
//     this.element = React.createRef();
//   }
//
//   componentDidMount() {
//     const {bcid, scale, height, value} = this.props;
//     // @ts-ignore
//     bwipjs(this.element.current, {
//       bcid,
//       text: value,
//       scale,
//       height,
//     }, function (err: Error) {
//       if (err) {
//         console.error(err);
//       }
//     });
//   }
//
//   render() {
//     return <canvas className={this.props.className} ref={this.element}/>;
//   }
// }

const OyBarcode: React.FC<OyBarcodeProps> = ({className, bcid, scale, height, value}) => {
  const element = useRef(null);
  useEffect(() => {
    if (!value || !element.current) {
      return
    }
    // @ts-ignore
    bwipjs(element.current, {
      bcid,
      text: value,
      scale,
      height,
    }, function (err: Error) {
      if (err) {
        console.error(err);
      }
    });
  }, [className, bcid, scale, height, value]);
  return (
    <canvas className={className} ref={element}/>
  );
};

OyBarcode.defaultProps = {
  className: '',
  bcid: 'code128',
  scale: 3,
  height: 10,
};

export default OyBarcode;
