import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import '@oyoyo/ui/src/styles/fonts.css';
import '@oyoyo/ui/src/postcss.css';
import Layout from './Layout';
import FabricPatternsPage from "./pages/FabricPatternsPage";
import FulfillmentPage from "./pages/FulfillmentPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import OrderStatsOverviewPage from "./pages/OrderStatsOverviewPage";
import PrintJobsCreatePage from './pages/PrintJobsCreatePage';
import PrintJobsDetailPage from "./pages/PrintJobsDetailPage";
import PrintJobsOverviewPage from "./pages/PrintJobsOverviewPage";
import * as serviceWorker from './serviceWorker';

ReactDOM.render((<Router>
  <Layout>
    <ToastProvider placement={'bottom-right'}>
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={'/'}>
            <FulfillmentPage/>
          </Route>
          <Route exact path={'/fabric-patterns'}>
            <FabricPatternsPage/>
          </Route>
          <Route exact path={'/order-stats/overview'}>
            <OrderStatsOverviewPage/>
          </Route>
          <Route exact path={'/print-jobs'}>
            <PrintJobsOverviewPage scan={false}/>
          </Route>
          <Route exact path={'/print-jobs/create'}>
            <PrintJobsCreatePage/>
          </Route>
          <Route exact path={'/print-jobs/scan'}>
            <PrintJobsOverviewPage scan={true}/>
          </Route>
          <Route path={'/print-jobs/create/:date'}>
            <PrintJobsCreatePage/>
          </Route>
          <Route path={`/print-jobs/:id`}>
            <PrintJobsDetailPage/>
          </Route>
          <Route path={`/order-details/:id`}>
            <OrderDetailsPage/>
          </Route>
        </Switch>
      </Suspense>
    </ToastProvider>
  </Layout>
</Router>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
