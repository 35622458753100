import React from 'react';
import clsx from "clsx";

export interface OyContainerProps {
  className?: string,
  wrapperClassName?: string,
}

const OyContainer: React.FC<OyContainerProps> = ({className, wrapperClassName, children}) => {
  const inner = (
    <section className={clsx('container mx-auto p-4', className)}>
      {children}
    </section>
  );

  if (wrapperClassName) {
    return (
      <div className={wrapperClassName}>
        {inner}
      </div>
    );
  }

  return inner;
};

OyContainer.defaultProps = {
  className: '',
  wrapperClassName: '',
};

export default OyContainer;
