import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  cacheExchange,
  createClient, createRequest,
  debugExchange,
  fetchExchange, GraphQLRequest, RequestPolicy,
  subscriptionExchange,
} from 'urql';
import {pipe, subscribe} from "wonka";

const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_API_GRAPHQL_WEBSOCKET as string,
  {
    reconnect: true,
    timeout: 30000,
    connectionParams: () => ({
      headers: {
        // Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        'x-hasura-admin-secret' : process.env.REACT_APP_API_HASURA_SECRET,
      }
    }),
  }
);

subscriptionClient.onError((err) => {
  console.warn(err)
});

const client = createClient({
  url: process.env.REACT_APP_API_GRAPHQL_HTTP as string,
  fetchOptions: () => {
    // const token = window.localStorage.getItem('accessToken');
    return {
      headers: {
        // Authorization: token ? `Bearer ${token}` : "",
        'x-hasura-admin-secret' : process.env.REACT_APP_API_HASURA_SECRET as string,
      }
    }
  },
  exchanges: [
    debugExchange,
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: operation => subscriptionClient.request(operation),
    }),
  ],
});

const executeQuery: any = (query: GraphQLRequest, variables: object, requestPolicy: RequestPolicy) => {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    const request = createRequest(query, variables);
    pipe(
      client.executeQuery(request, {
        requestPolicy
      }),
      subscribe(({ data, error }) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      })
    );
  });
};

export {
  client,
  executeQuery,
}
