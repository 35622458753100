import React from 'react';
import {ReactComponent as Logo} from './textil-werk.svg'

export interface TwLogoProps {
  className?: string,
}

const TwLogo: React.FC<TwLogoProps> = ({className}) => {
  return (
    <Logo className={className}/>
  );
};

TwLogo.defaultProps = {
  className: 'w-32',
};

export default TwLogo;
