import React from 'react';
// import OySelect from "../../../components/OySelect/OySelect";
import OyButton from "../../../components/OyButton/OyButton";
import OyContainer from "../../../components/OyContainer/OyContainer";
import {ReactComponent as IconDelete} from "feather-icons/dist/icons/trash-2.svg"
import clsx from "clsx";
import {PrintJobItem} from "../types/print-job-item";
import {motion, Variants} from "framer-motion"
import {PrintJob} from "../types/print-job";

export interface ReprintJobListProps {
  className?: string,
  titleClassName?: string,
  title: string,
  printJobItems: PrintJobItem[],
  onDelete(jobId: number, itemId: number): void,
  onReprint(): void,
}

// const numberOptions = [{value: 1, label: '1'}];

export interface ReprintJobsList {
  parentId: number,
  parentName: string,
  items: PrintJobItem[],
}

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item: Variants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
};

const separateItems = (printJobItems: PrintJobItem[]) => {
  const jobsList: { [id: number]: ReprintJobsList} = {};
  printJobItems.forEach(item => {
    if (!item.job) {
      return;
    }
    if (typeof jobsList[item.job.id] === 'undefined') {
      jobsList[item.job.id] = {
        parentId: item.job.id,
        parentName: item.job.name,
        items: [],
      }
    }
    jobsList[item.job.id].items.push(item);
  });
  return Object.values(jobsList);
};

const ReprintJobList: React.FC<ReprintJobListProps> = ({className, titleClassName, title, printJobItems, onDelete, onReprint}) => {
  if (!printJobItems.length) {
    return (<></>);
  }
  const jobs = separateItems(printJobItems);
  const handleClick = (job: PrintJob | undefined, itemId: number) => () => {
    if (job) {
      onDelete(job.id, itemId);
    }
  };
  // @ts-ignore
  return (
    <OyContainer className={className}>
      <h2 className={clsx('text-2xl font-bold', titleClassName)}>selected files for reprint</h2>
      {jobs.map(job => (
        <motion.div key={job.parentId} variants={container} initial={'hidden'} animate={'show'}>
          <p>reprints from order number <span className='font-medium'>{job.parentName}</span></p>
          <div className='flex flex-col mt-2 mb-8'>
            {job.items.map(({id, label, designUrl, designId, job}) => (
              <motion.div key={id} className='flex items-center p-2 border-b-2 hover:bg-gray-200' variants={item}>
                <div className='w-12 relative'>
                  <div className='aspect-ratio-square'/>
                  <div className='absolute inset-0 flex items-center justify-center overflow-hidden'>
                    <img src={designUrl} alt={designId}/>
                  </div>
                </div>
                <div className='flex-1 mx-2'>{label}</div>
                {/*<OySelect currentValue={numberOptions[0]} values={numberOptions} handleChange={() => {}}/>*/}
                <button className='ml-4 hover:text-red-500' onClick={handleClick(job, id)}>
                  <IconDelete className='w-6 h-6'/>
                </button>
              </motion.div>
            ))}
          </div>
        </motion.div>
      ))}
      <div className='mt-4 flex justify-end'>
        <OyButton label={`create reprint order${jobs.length !== 1 ? 's' : ''}`} color={'red'} disabled={!jobs.length} onClick={onReprint}/>
      </div>
    </OyContainer>
  );
};

ReprintJobList.defaultProps = {
  className: '',
  titleClassName: '',
};

export default ReprintJobList;
