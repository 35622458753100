import React from 'react';
import clsx from "clsx";

export interface OyBreadcrumbItem {
  label: string,
  url: string,
}

export interface OyBreadcrumbsProps {
  className?: string,
  items: OyBreadcrumbItem[],
  component?: string | React.ElementType,
  linkProperty?: string,
}

const OyBreadcrumbs: React.FC<OyBreadcrumbsProps> = ({className, items, component: componentProp, linkProperty}) => {
  const Component = componentProp || 'a';
  return (
    <ul className={clsx('flex', className)}>
      {items.map(({label, url}, index) => {
        const lastIndex = items.length - 1;
        const componentProps = {[linkProperty as string]: url};
        return (
          <React.Fragment key={index}>
            <li>
              {index === lastIndex ? (
                <span className='text-gray-500'>{label}</span>
              ) : (
                <Component className='text-blue-500 hover:text-blue-800' {...componentProps}>{label}</Component>
              )}
            </li>
            {index < lastIndex && (
              <li className='mx-2'>/</li>
            )}
          </React.Fragment>
        )
      })}
    </ul>
  );
};

OyBreadcrumbs.defaultProps = {
  className: '',
  component: 'a',
  linkProperty: 'href',
};

export default OyBreadcrumbs;
