import React from 'react';
import clsx from "clsx";
import {motion, Variants} from "framer-motion";
import {ReactComponent as IconDownload} from "feather-icons/dist/icons/download-cloud.svg"
import {ReactComponent as IconClose} from "feather-icons/dist/icons/x.svg"
import {ReactComponent as IconConfirm} from "feather-icons/dist/icons/check-circle.svg"
import {ReactComponent as IconReprint} from "feather-icons/dist/icons/printer.svg"
import {useKey} from 'react-use';

import OyButton from "../../../components/OyButton/OyButton";
import {PrintJobItem} from "../types/print-job-item";
import {OrderDetails} from "../types/order-details";
import {SimpleImg} from "react-simple-img";

export interface ScanModalProps {
  className?: string,
  item: PrintJobItem,
  orderDetails?: OrderDetails,
  confirmItem(): void,
  downloadItem?(): void,
  reprintItem(): void,
  closeModal(): void,
}

const modalVariants: Variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const ScanModal: React.FC<ScanModalProps> = ({className, item, orderDetails, closeModal, confirmItem, downloadItem, reprintItem, children}) => {
  useKey('Escape', closeModal, {}, [item]);
  useKey('r', reprintItem, {}, [item]);
  useKey('c', confirmItem, {}, [item]);
  const confirmLabel = 2 === item.status ? 'unconfirm' : 'confirm';
  const confirmColor = 2 === item.status ? 'gray' : 'green';
  return (
    <motion.div
      key={'modal'}
      variants={modalVariants}
      initial={"closed"}
      animate={"open"}
      exit={"closed"}
      className='fixed z-2000 inset-0 overflow-auto flex'
    >
      <div className='fixed w-full h-full bg-gray-900 opacity-50' onClick={closeModal}/>
      <motion.div animate={{scale: [0.75, 1]}} transition={{ duration: 0.3 }} className={clsx('relative p-4 bg-gray-200 w-full max-w-md m-auto flex-col flex shadow-xl', className)}>
        <button className='self-end hover:text-gray-600' onClick={closeModal}>
          <IconClose className='w-6 h-6'/>
        </button>
        {children}
        {(item.designConfiguration && item.designConfiguration.texture) && (
          <div className='my-4 min-h-screen-70 relative'>
            <div className='absolute inset-0 flex items-center justify-center overflow-hidden'>
              <SimpleImg src={orderDetails ? orderDetails.images[0] : item.designUrl} />
            </div>
          </div>
        )}
        {(item.designConfiguration && !item.designConfiguration.texture && item.designConfiguration.backgroundColor) && (
          <div className='flex p-4 my-4 justify-center' style={{backgroundColor: item.designConfiguration.backgroundColor}}>empty side</div>
        )}
        <div className='grid md:grid-columns-3 md:grid-gap-4'>
          <OyButton color={confirmColor} label={confirmLabel} icon={<IconConfirm className={'w-6 h-6 mr-1'}/>}
                    iconPosition={'left'} onClick={confirmItem}/>
          {downloadItem && (
            <OyButton color={'blue'} label={'PDF file'} icon={<IconDownload className={'w-6 h-6 mr-1'}/>}
                      iconPosition={'left'} onClick={downloadItem}/>
          )}
          <OyButton color={'red'} label={'reprint'} icon={<IconReprint className={'w-6 h-6 mr-1'}/>}
                    iconPosition={'left'} onClick={reprintItem} className={clsx({'col-start-3': !downloadItem})}/>
        </div>
      </motion.div>
    </motion.div>
  );
};

ScanModal.defaultProps = {
  className: '',
};

export default ScanModal;
